import React from "react"

import { Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactEnPage = () => {
  const data = useStaticQuery(graphql`
      query {
          wechat: file(relativePath: { eq: "wechat.png" }) {
              childImageSharp {
                  fluid(quality: 75) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

  const [isErrorForm, setErrorForm] = React.useState(false)
  const [isSubmit, setIsSubmit] = React.useState(false)
  const [isResponse, setResponse] = React.useState("")

  const validateForm = (e) => {
    setIsSubmit(true)

    e.preventDefault()
    let target = e.target
    let data = {
      name: target?.name?.value,
      company: target?.company?.value,
      email: target?.email?.value,
      phone: target?.phone?.value,
      subject: target?.subject?.value,
      message: target?.message?.value,
    }
    
    if(data.name && data.email && data.message ){
      window.location = `mailto:dispatch@pioneersolutions19.com?subject=${data?.subject}&body=Company: ${data?.company}, <br /> Contact Name: ${data?.name} <br /> Phone: ${data?.phone}, <br /> Email: ${data?.email}, <br /> Content: ${data?.message}`

      setIsSubmit(false)

    } else {

      setErrorForm(true)
    }
  }

  return(

    <Layout en={true}>
        <SEO title="Contact us" />

        <section className="container-md pt-5">
          <h1 className="h2 text-dark mb-5">At Pioneer Solutions we treat all shipments with importance and urgency</h1>
          
          <div className="row py-5">
            <form 
              className={`col-md-7 m-1 m-md-0 border py-4 mb-5 rounded bg-white ${isErrorForm ? "was-validated" : ""}`} 
              // method="POST" 
              // action="http://bajacargosolutions.com/contact.php"
              onSubmit={(e) => validateForm(e)}
              // onSubmit="mailto:info@dovlius.com"
            >
              <h3 className="pb-3">Send a message</h3>
              <div className="form-row">
                <div className="form-group mb-4 col-md-6">
                  <label htmlFor="name"> *Contact Name</label>
                  <input type="text" className="form-control" name="name" id="name" placeholder="Name" required/>
                  <div className="valid-feedback">
                    Good
                  </div>
                  <div className="invalid-feedback">
                    Required field.
                  </div>
                </div>
                <div className="form-group mb-4 col-md-6">
                  <label htmlFor="company">Company</label>
                  <input type="text" className="form-control" name="company" id="company" placeholder="Company" />
                </div>
              </div>
              <div className="form-group mb-4">
                <label htmlFor="email">*Correo de Contacto</label>
                <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" required />
                <div className="valid-feedback">
                  Good
                </div>
                <div className="invalid-feedback">
                  Required field.
                </div>
              </div>
              <div className="form-group mb-4">
                <label htmlFor="phone">*Telefono de Contacto</label>
                <input type="text" className="form-control" name="phone" id="phone" placeholder="(000) 000 0000" required />
                  <div className="valid-feedback">
                    Good
                  </div>
                  <div className="invalid-feedback">
                    Required field.
                  </div>
              </div>
              <div className="form-group mb-4">
                <label htmlFor="subject">*Subject</label>
                <select name="subject" id="subject" className="form-control" required>
                  <option defaultValue>More Information</option>
                  <option>Quote</option>
                  <option>Schedule appointment</option>
                </select>
              </div>

              <div className="form-group mb-4">
                <div className="mb-3">
                  <label htmlFor="message">*Message</label>
                  <textarea className="form-control" name="message" id="message" placeholder="Message..." rows="7" required></textarea>
                  <div className="valid-feedback">
                    Good
                  </div>
                  <div className="invalid-feedback">
                    Required field.
                  </div>
                </div>
              </div>
                
              <div className="form-group mb-4 py-3">
                <div className="form-check">
                  <input className="form-check-input custom-control-input" type="checkbox" id="check" required />
                  <label className="form-check-label custom-control-label" htmlFor="check" >
                    I accept <Link to="terms">Térms & Conditions</Link>
                  </label>
                  <div className="valid-feedback">
                    Good
                  </div>
                  <div className="invalid-feedback">
                    Required field
                  </div>
                </div>
              </div>
              {
                isResponse != ''
                ?
                <div className="alert alert-success">{isResponse}</div>
                :
                null
              }
              {
                isSubmit
                ?
                <button className="btn btn-primary" type="submit" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Sending...
                </button>
                :
                <button type="submit" className="btn btn-primary">Send now</button>
              }
            </form>
            
            <div className="col-md-5">
              <h6 className="text-black pb-2">Address</h6>
              <p className="pb-4">Pioneer Solutions LLC 19223 Colima Road #747 Rowland Heights, CA. 91748</p>
         
              <h6 className="text-black pb-2">Emails</h6>
              
              <ul className="pb-4">
                {/* <li className="mb-2"><i className="mdi"></i><b>Mobile EEUU:</b><br /> (909) 904 9064</li> */}
                <li className="mb-2"><i className="mdi"></i><b>Steven Shen:</b><br /> steven@pioneersolutions19.com</li>
                <li className="mb-2"><i className="mdi"></i><b>Abdul Ahmad:</b><br /> abdul@pioneersolutions19.com</li>
                <li className="mb-2"><i className="mdi"></i><b>Jose L. Torres:</b><br /> jose@pioneersolutions19.com</li>
              </ul>

              <h6 className="text-black pb-2">Phone</h6>

              <ul className="pb-4">
                <li className="mb-2"><i className="mdi"></i><b>Mobile EEUU:</b><br /> (909) 904 9064</li>
              </ul>

              <h6 className="text-black pb-2">WeChat</h6>
              <p className="pb-4">Code: taimek001</p>

            </div>
          </div>

        </section>

        <section className="container-md py-5 rounded">
            <div className="d-flex flex-column align-items-start justify-content-center">
              <h1 className="text-dark pb-4">The Pioneer Difference</h1>
              <p className="">Our mission is to provide solutions to our clients while offering exceptional service and setting the standard for professionalism in the logistics industry.</p>
            </div>
        </section>

        <section>
          <iframe className="border-top" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3308.189364091724!2d-117.88633428255613!3d33.98766950000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c32af955b76883%3A0x65f04bbfa4ec01ae!2s19223%20Colima%20Rd%20%23747%2C%20Rowland%20Heights%2C%20CA%2091748%2C%20EE.%20UU.!5e0!3m2!1ses!2smx!4v1668597371641!5m2!1ses!2smx" width="100%" height="400" frameBorder="0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </section>

    </Layout>
  )
}

export default ContactEnPage
